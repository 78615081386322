import * as React from 'react'

import PostItem from './PostItem'

const PostList = ({ posts }) => (
  <div className="post-list">
    {posts.map(post => <PostItem key={post.fields.slug} post={post} />)}
  </div>
)

export default PostList
