import * as React from 'react'

import Link from 'gatsby-link'

const PostItem = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  return (
    <div className="post-item">
      <h3 className="post-title">
        <Link to={post.fields.slug}>
          {title}
        </Link>
      </h3>
      <small className="post-date">{post.frontmatter.date}</small>
      <p
        className="post-excerpt"
        dangerouslySetInnerHTML={{ __html: post.frontmatter.summary || post.excerpt }}
      />
    </div>
  )
}

export default PostItem
